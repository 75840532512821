import React from 'react'
import LoadingAnimation from 'components/Loader'
import { XIcon } from 'assets/icons/XIcon'
import { Image } from 'assets/images'
import * as S from './styled'

export function OnboardingScreen() {
  return (
    <S.OnboardingContainer>
      <S.OnboardingScreenOverlayBlock />
      <S.OkxWrapper>
        <S.OkxTitle>OKX week!</S.OkxTitle>
        <S.ImagesWrapper>
          <S.OnboardingImage src={Image.voyager} alt='OKX' />
          <XIcon />
          <S.OnboardingImage src={Image.okx} alt='OKX' />
        </S.ImagesWrapper>
      </S.OkxWrapper>
      <LoadingAnimation />
      <S.LoadingText>Loading</S.LoadingText>
      <S.OnboardingTitle>
        Welcome to the <br /> ultimate coin quest!
      </S.OnboardingTitle>
      <S.OnboardingSubtitle>
        Collect coins, level up, and unlock exciting rewards—let the adventure
        begin!
      </S.OnboardingSubtitle>
    </S.OnboardingContainer>
  )
}
