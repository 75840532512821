import React from 'react'

export function TotalCards() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17157C20 6.34315 20 8.22876 20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34315 20 5.17157 18.8284C4 17.6569 4 15.7712 4 12Z'
        stroke='#F8F8F8'
        strokeWidth='1.5'
      />
      <path
        d='M4 13.1185C4.61902 13.0398 5.24484 13.001 5.87171 13.0023C8.52365 12.9533 11.1106 13.6763 13.1711 15.0424C15.082 16.3094 16.4247 18.053 17 20'
        stroke='#F8F8F8'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M14.9998 9H15.0088'
        stroke='#F8F8F8'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
