import { API } from 'configs/api'
import axios from 'configs/axios'

export const userApi = {
  registerUser: (body) => {
    return axios.post(API.registerUser, body)
  },
  getMe: (body) => {
    return axios.post(API.getUserInfo, body)
  },
  test: (body) => {
    return axios.post(API.test, body)
  },
  registerReferral: (body) => {
    return axios.post(API.registerReferral, body)
  },
}
