import React from 'react'

export function TotalFriends() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M13.3499 7.4C13.3499 9.27777 11.8277 10.8 9.94993 10.8C8.07216 10.8 6.54993 9.27777 6.54993 7.4C6.54993 5.52223 8.07216 4 9.94993 4C11.8277 4 13.3499 5.52223 13.3499 7.4Z'
        stroke='#F8F8F8'
        strokeWidth='1.275'
      />
      <path
        d='M15.05 10.8C16.9277 10.8 18.45 9.27777 18.45 7.4C18.45 5.52223 16.9277 4 15.05 4'
        stroke='#F8F8F8'
        strokeWidth='1.275'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.65 13.35H8.25C5.90279 13.35 4 15.2528 4 17.6C4 18.5389 4.76112 19.3 5.7 19.3H14.2C15.1389 19.3 15.9 18.5389 15.9 17.6C15.9 15.2528 13.9972 13.35 11.65 13.35Z'
        stroke='#F8F8F8'
        strokeWidth='1.275'
        strokeLinejoin='round'
      />
      <path
        d='M16.75 13.35C19.0972 13.35 21 15.2528 21 17.6C21 18.5389 20.2389 19.3 19.3 19.3H18.025'
        stroke='#F8F8F8'
        strokeWidth='1.275'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
