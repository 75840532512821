export const LOADING_STATUSES = {
  idle: 'IDLE',
  pending: 'PENDING',
  succeeded: 'SUCCEEDED',
  failed: 'FAILED',
}

export const ROUTES = {
  home: '/',
  tasks: '/tasks',
  farm: '/farm',
  friends: '/friends',
  games: '/games',
  notFound: '*',
}

export const COLORS = {
  black: '#0F0F0F',
  black_28: '#282828',
  gray_3B: '#3B3B3B80',
  dark_43: '#434343',
  gray: '#8F8F8F',
  gray_64: '#646464',
  gray_80: '#808080',
  gray_100: '#404145',
  grey_9e: '#9e9e9e',
  grey_c1: '#C1C1C1',
  blue_white: '#E2E9F9',
  white: '#FFFFFF',
  white_f8: '#F8F8F8',
  blue: '#007AFF',
  blue_01: '#013479',
  blue_02: '#021B74',
  blue_03: '#021B7433',
  blue_00: '#007AFF',
  blue_002: '#002351',
  blue_004: '#004098',
  blue_op: '#296fe599',
  red: '#B3261E',
  green: '#4BB543',
}

export const TASK_SOURCES = {
  youtube: 'youtube',
  instagram: 'instagram',
  facebook: 'facebook',
  twitter: 'twitter',
  telegram: 'telegram',
  game: 'game',
  sponsor: 'sponsor',
}

export const TASK_SPONSORS = {
  okx: 'okx',
  stake: 'stake',
  pocketOption: 'pocketOption',
}

export const TASK_BUTTONS_STATES = {
  done: 'Done',
  start: 'Start',
  register: 'Register',
  play: 'Play',
  collect: 'Collect',
  check: 'Check',
  claim: 'Claim',
  share: 'Share',
  follow: 'Follow',
}

export const TASK_TYPES = {
  all: 'all',
  once: 'invite',
  daily: 'daily',
}

export const DAILY_TASK_TYPES = {
  subscribe: 'subscribe',
  story: 'story',
}

export const GAME_TYPES = {
  quiz: 'quiz',
  puzzle: 'puzzle',
  roulette: 'roulette',
}

export const TASK_PRIORITIES = {
  high: 'high',
  medium: 'medium',
  low: 'low',
}

export const CONFIRMATION_TASKS = [
  TASK_SPONSORS.okx,
  TASK_SPONSORS.stake,
  TASK_SPONSORS.pocketOption,
]

export const PRIZES = {
  1: 1000,
  2: 1500,
  3: 2000,
  4: 2500,
  5: 3000,
  6: 3500,
  7: 4000,
  8: 4500,
}
