import React from 'react'
import * as S from './styled'

function Skeleton({ children, mode = 'dark', direction }) {
  return (
    <S.Wrapper mode={mode} direction={direction}>
      {children}
    </S.Wrapper>
  )
}

function Line({ height = '50%', width = '50%', radius = '6px' }) {
  return <S.Line height={height} width={width} radius={radius} />
}

function Square({ size = '50%' }) {
  return <S.Square size={size} />
}

function Circle({ size = '50%' }) {
  return <S.Circle size={size} />
}

Skeleton.Line = Line
Skeleton.Square = Square
Skeleton.Circle = Circle

export default Skeleton
