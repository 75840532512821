import styled, { keyframes, css } from 'styled-components'
import { COLORS } from 'utils/constants'

const common = css`
  margin-bottom: 6px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
`

export const skeletonKeyframe = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`

export const Wrapper = styled.div`
  padding: 15px;
  width: auto;
  background: ${({ mode }) => (mode === 'dark' ? COLORS.black : COLORS.white)};
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction = 'row' }) => direction};
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
`

export const Line = styled.div`
  ${common};
  border-radius: ${({ radius = '6px' }) => radius};
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};
  animation: ${skeletonKeyframe} 2s infinite ease-out;
`

export const Square = styled.div`
  ${common};
  border-radius: 5px;
  height: ${({ size = '100%' }) => size};
  width: ${({ size = '100%' }) => size};
  animation: ${skeletonKeyframe} 2s infinite ease-out;
`

export const Circle = styled.div`
  ${common};
  border-radius: 50%;
  height: ${({ size = '100%' }) => size};
  width: ${({ size = '100%' }) => size};
  animation: ${skeletonKeyframe} 2s infinite ease-out;
`
