import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import {
  getTasks,
  completeTask,
  completeTasks,
  getDailyTasks,
  getSocialTasks,
  completeDailyTask,
  completeSocialTask,
  gainDailyTask,
  gainSocialTask,
} from './actions'

const initialState = {
  tasks: [],
  dailyTasks: [],
  socialTasks: [],
  storyImgUrl: null,
  tasksReward: 0,
  tasksCardsCount: 0,
  loadTasksListStatus: LOADING_STATUSES.idle,
  loadDailyTasksListStatus: LOADING_STATUSES.idle,
  loadSocialTasksListStatus: LOADING_STATUSES.idle,
  completeTaskListStatus: LOADING_STATUSES.idle,
  completeDetectableTaskStatus: LOADING_STATUSES.idle,
  completeTasksListStatus: LOADING_STATUSES.idle,
}

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetCompleteTasksStatus: (state) => {
      state.completeTasksListStatus = LOADING_STATUSES.idle
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getTasks.pending, (state) => {
      state.loadTasksListStatus = LOADING_STATUSES.pending
    })
    addCase(getTasks.fulfilled, (state, { payload }) => {
      state.tasks = payload
      state.loadTasksListStatus = LOADING_STATUSES.succeeded
    })
    addCase(getTasks.rejected, (state) => {
      state.loadTasksListStatus = LOADING_STATUSES.failed
    })
    addCase(getDailyTasks.pending, (state) => {
      state.loadDailyTasksListStatus = LOADING_STATUSES.pending
    })
    addCase(getDailyTasks.fulfilled, (state, { payload }) => {
      state.dailyTasks = payload.tasks
      state.storyImgUrl = payload.storyImgUrl
      state.loadDailyTasksListStatus = LOADING_STATUSES.succeeded
    })
    addCase(getDailyTasks.rejected, (state) => {
      state.loadDailyTasksListStatus = LOADING_STATUSES.failed
    })
    addCase(getSocialTasks.pending, (state) => {
      state.loadSocialTasksListStatus = LOADING_STATUSES.pending
    })
    addCase(getSocialTasks.fulfilled, (state, { payload }) => {
      state.socialTasks = payload
      state.loadSocialTasksListStatus = LOADING_STATUSES.succeeded
    })
    addCase(getSocialTasks.rejected, (state) => {
      state.loadSocialTasksListStatus = LOADING_STATUSES.failed
    })
    addCase(completeTask.pending, (state) => {
      state.completeTaskListStatus = LOADING_STATUSES.pending
    })
    addCase(completeTask.fulfilled, (state, { payload }) => {
      const taskIndex = state.tasks.findIndex(
        (task) => task._id === payload._id
      )
      if (taskIndex !== -1) {
        state.tasks[taskIndex].gained = true
      }
      state.completeTaskListStatus = LOADING_STATUSES.succeeded
    })
    addCase(completeTask.rejected, (state) => {
      state.completeTaskListStatus = LOADING_STATUSES.failed
    })
    addCase(completeTasks.pending, (state) => {
      state.completeTasksListStatus = LOADING_STATUSES.pending
    })
    addCase(completeTasks.fulfilled, (state, { payload }) => {
      state.tasksCardsCount = payload.cardsCount
      state.tasksReward = payload.coins
      state.tasks.forEach((task) => {
        if (task.completed) {
          task.gained = true
        }
      })
      state.completeTasksListStatus = LOADING_STATUSES.succeeded
    })
    addCase(completeTasks.rejected, (state) => {
      state.completeTasksListStatus = LOADING_STATUSES.failed
    })
    addCase(completeDailyTask.pending, (state) => {
      state.completeDetectableTaskStatus = LOADING_STATUSES.pending
    })
    addCase(completeDailyTask.fulfilled, (state, { meta }) => {
      state.dailyTasks.forEach((task) => {
        if (task._id === meta.arg.taskId) {
          task.completed = true
        }
      })
      state.completeDetectableTaskStatus = LOADING_STATUSES.succeeded
    })
    addCase(completeDailyTask.rejected, (state) => {
      state.completeDetectableTaskStatus = LOADING_STATUSES.failed
    })
    addCase(completeSocialTask.pending, (state) => {
      state.completeDetectableTaskStatus = LOADING_STATUSES.pending
    })
    addCase(completeSocialTask.fulfilled, (state, { payload }) => {
      state.socialTasks.forEach((task) => {
        if (task._id === payload.taskId) {
          task.completed = true
        }
      })
      state.completeDetectableTaskStatus = LOADING_STATUSES.succeeded
    })
    addCase(completeSocialTask.rejected, (state) => {
      state.completeDetectableTaskStatus = LOADING_STATUSES.failed
    })
    addCase(gainDailyTask.pending, (state) => {
      state.completeTaskListStatus = LOADING_STATUSES.pending
    })
    addCase(gainDailyTask.fulfilled, (state, { meta }) => {
      state.dailyTasks.forEach((task) => {
        if (task._id === meta.arg.taskId) {
          task.gained = true
        }
      })
      state.completeTaskListStatus = LOADING_STATUSES.succeeded
    })
    addCase(gainDailyTask.rejected, (state, { meta }) => {
      state.dailyTasks.forEach((task) => {
        if (task._id === meta.arg.taskId) {
          task.completed = false
        }
      })
      state.completeTaskListStatus = LOADING_STATUSES.failed
    })
    addCase(gainSocialTask.pending, (state) => {
      state.completeTaskListStatus = LOADING_STATUSES.pending
    })
    addCase(gainSocialTask.fulfilled, (state, { meta }) => {
      state.socialTasks.forEach((task) => {
        if (task._id === meta.arg.taskId) {
          task.gained = true
        }
      })
      state.completeTaskListStatus = LOADING_STATUSES.succeeded
    })
    addCase(gainSocialTask.rejected, (state) => {
      state.completeTaskListStatus = LOADING_STATUSES.failed
    })
  },
})

export const tasksReducer = tasksSlice.reducer

export const tasksActions = {
  ...tasksSlice.actions,
  getTasks,
  completeTask,
  completeDailyTask,
  completeSocialTask,
  completeTasks,
  gainDailyTask,
  gainSocialTask,
  resetCompleteTasksStatus: tasksSlice.actions.resetCompleteTasksStatus,
}
