import React from 'react'
import Skeleton from 'common/Skeleton'
import { SkeletonCardItem } from 'components/Skeletons/CardSkeleton/SkeletonCardItem'
import * as S from './styled'

export function CardSkeleton() {
  return (
    <S.Wrapper>
      <S.Content>
        <Skeleton direction='column'>
          <S.CardTitle>
            <Skeleton.Line height='24px' />
          </S.CardTitle>
          <S.CardSection>
            <SkeletonCardItem />
            <SkeletonCardItem />
            <SkeletonCardItem />
            <SkeletonCardItem />
            <SkeletonCardItem />
            <SkeletonCardItem />
          </S.CardSection>
        </Skeleton>
      </S.Content>
    </S.Wrapper>
  )
}
