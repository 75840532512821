import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'

export const RouletteView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 24px;
  ${overflowScroll}
`
