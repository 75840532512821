import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const GameHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1;
`

export const GameTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.blue};
`

export const GameSubtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.white_f8};
`

export const GameDescription = styled.p`
  color: ${COLORS.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

export const GameImage = styled.img`
  max-width: ${({ size }) => size};
  max-height: ${({ size }) => size};
  width: 100%;
  height: 100%;
  object-fit: cover;
`
