import { API } from 'configs/api'
import axios from 'configs/axios'

export const farmApi = {
  checkFarmingStatus: () => {
    return axios.get(API.farm)
  },
  startFarming: () => {
    return axios.post(API.startFarming)
  },
}
