export const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  const padZero = (num) => (num < 10 ? `0${num}` : num)

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`
}
