import React from 'react'

export function XIcon() {
  return (
    <svg
      width='26'
      height='23'
      viewBox='0 0 26 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8133 23L12.6533 17.112L9.19725 23H1.10125L8.55725 11.32L0.78125 0.344H9.19725L13.1973 6.008L16.5253 0.344H24.6213L17.2933 11.8L25.2293 23H16.8133Z'
        fill='white'
      />
    </svg>
  )
}
