import React from 'react'
import StyledSingleText from './styled'

function SingleText({
  children,
  textColor,
  fontSize,
  margin,
  padding,
  fontWeight,
}) {
  return (
    <StyledSingleText
      textColor={textColor}
      fontSize={fontSize}
      margin={margin}
      padding={padding}
      fontWeight={fontWeight}
    >
      {children}
    </StyledSingleText>
  )
}

export default SingleText
