import styled from 'styled-components'
import { COLORS } from 'utils/constants'
import ModalOverlay from 'assets/images/modal-overlay.png'

export const StyledModalWrapper = styled.div`
  top: 50%;
  left: 50%;
  padding: 16px;
  position: fixed;
  font-family: Poppins;
  z-index: 1001;
  max-width: 100%;
  border-radius: 16px;
  width: calc(100vw - 84px);
  transform: translate(-50%, -50%);
  border: 1px solid ${COLORS.gray_3B};
  background-color: ${COLORS.black_28};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`

export const StyledModal = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const StyledOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  background: url(${ModalOverlay});
`

export const ModalCloseButton = styled.button`
  top: 16px;
  right: 16px;
  z-index: 1002;
  border: none;
  position: absolute;
  background-color: transparent;
`
