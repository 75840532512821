import TasksList from 'components/TasksList'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDailyTasks, getSocialTasks, getTasks } from 'store/tasks/actions'
import { tasksSelector } from 'store/tasks/selectors'

function Tasks() {
  const { tasks, dailyTasks, socialTasks } = useSelector(tasksSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTasks())
    dispatch(getDailyTasks())
    dispatch(getSocialTasks())
  }, [])

  return (
    <TasksList
      tasks={tasks}
      dailyTasks={dailyTasks}
      socialTasks={socialTasks}
    />
  )
}

export default Tasks
