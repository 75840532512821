import { Image } from 'assets/images'
import Button from 'common/Button'
import * as S from './styled'

export function GameListItem({
  image,
  game,
  description,
  reward,
  canPlay,
  onGameClick,
}) {
  return (
    <S.GameItem>
      <S.GameImage src={image} alt='game' />
      <S.GameInfo>
        <S.GameDescription>{description}</S.GameDescription>
        <S.GameRewardsWrapper>
          <S.GameRewardCoin src={Image.coin} alt='coin' />
          <S.GameReward>{reward}</S.GameReward>
        </S.GameRewardsWrapper>
      </S.GameInfo>
      <Button
        text='Play'
        onClick={() => onGameClick(game, canPlay)}
        maxHeight='42px'
        maxWidth='57px'
      />
    </S.GameItem>
  )
}
