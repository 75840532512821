import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const StyledTooltip = styled.div`
  border-radius: 8px;
  text-align: center;
  position: absolute;
  padding: 4px 8px;
  font-size: 14px;
  white-space: nowrap;
  background-color: ${COLORS.black_28};
  left: 50%;
  transform: translateX(-50%) translateY(calc(100% + 8px));
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid ${COLORS.gray_3B};
  z-index: 1;

  &:after {
    content: '';
    top: -18px;
    left: 50%;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    transform: translateX(-50%);
    border-color: transparent transparent ${COLORS.black_28} transparent;
  }
`

export const StyledTooltipWrapper = styled.div`
  position: relative;
  display: flex;
`
