import styled, { keyframes } from 'styled-components'
import { COLORS } from 'utils/constants'

// Animation for sliding the dialog from bottom to top
const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const DialogWrapper = styled.div`
  font-family: Poppins;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 430px;
  padding: 16px 16px 54px 16px;
  border-radius: 24px 24px 0 0;
  background: ${COLORS.black_28};
  animation: ${slideUp} 0.3s ease-out;
  position: relative;
`

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DialogTitle = styled.h2`
  color: ${COLORS.white};
  width: max-content;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`
