import { ROULETTE_IMAGES } from 'assets/images'
import { PRIZES } from './constants'

export function shuffleImages() {
  return Object.entries(ROULETTE_IMAGES).sort(() => Math.random() - 0.5)
}

export function calculateSpins(refs) {
  return refs.map(() => {
    const winnerIndex = Math.floor(Math.random() * 7)
    const randomLapsCount = Math.floor(Math.random() * (6 - 4 + 1)) + 4
    const degrees = winnerIndex * 45 + randomLapsCount * 360
    const duration = Math.floor(Math.random() * (4200 - 3800 + 1)) + 3800
    return {
      degrees,
      winnerIndex,
      duration,
    }
  })
}

export function calculatePrize(spins, wheels) {
  const prizeSum = spins.reduce((sum, spin, index) => {
    const prizeKey = wheels[index].shuffledImages[7 - spin.winnerIndex][0]
    return sum + PRIZES[prizeKey]
  }, 0)
  return Math.min(10000, Math.max(1000, prizeSum))
}

export function spinWheels(spins, wheels, animations) {
  spins.forEach((spin, index) => {
    const wheel = wheels[index].current
    const animRef = animations.current[index]
    animRef.current = wheel.animate(
      [
        { transform: `rotate(${0}deg)` },
        { transform: `rotate(${spin.degrees}deg)` },
      ],
      {
        duration: spin.duration,
        direction: 'normal',
        easing: 'cubic-bezier(0.440, -0.205, 0.000, 1.130)',
        fill: 'forwards',
        iterations: 1,
      }
    )
  })
}
