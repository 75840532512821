import axios from 'configs/axios'
import { API } from 'configs/api'

export const cardApi = {
  getCards: () => {
    return axios.get(API.getCards)
  },
  getCardById: (body) => {
    return axios.post(API.getCardById, body)
  },
  incrementAvailableCardsByOne: () => {
    return axios.post(API.incrementAvailableCardsByOne)
  },
}
