import { createAsyncThunk } from '@reduxjs/toolkit'
import { friendsApi } from './services'

export const getFriends = createAsyncThunk(
  'friends/getFriends',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await friendsApi.getFriends()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const friendsCollect = createAsyncThunk(
  'friends/friendsCollect',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await friendsApi.friendsCollect()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
