import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const QuizView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 20px;
  ${overflowScroll}
`

export const QuizWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const QuizQuestionWrapper = styled.div`
  border: 2px ${COLORS.blue_02} solid;
  border-radius: 6px;
  background-color: ${COLORS.blue_03};
  width: 100%;
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const QuizQuestion = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.white_f8};
`

export const QuizOptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${overflowScroll}
`

export const QuizOption = styled.button`
  text-align: left;
  background-color: transparent;
  color: ${COLORS.white_f8};
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid;
  transition: all 0.2s ease-out;
  border-color: ${({ borderColor }) => borderColor};
`
