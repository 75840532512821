import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const FriendsNotExistScreenWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 300px);
  padding-bottom: 16px;
  margin-block: 32px;
  ${overflowScroll}
`

export const InviteFriendsBanner = styled.div`
  width: 100%;
  height: 84px;
  border-radius: 6px;
  background-color: ${COLORS.black_28};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BannerLeftView = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
  gap: 4px;
`

export const BannerTitle = styled.span`
  color: ${COLORS.blue};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const BannerText = styled.span`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 8px;
`

export const BannerRightView = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

export const Emoji = styled.img`
  width: 100%;
`

export const InviteStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

export const Steps = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    .line {
      display: none;
    }
  }
`

export const StepTopPart = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StepCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${COLORS.white_f8};
`

export const StepTitle = styled.span`
  color: ${COLORS.white_f8};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`

export const Line = styled.div`
  width: 1px;
  height: 44px;
  border-left: 1px dashed ${COLORS.white_f8};
  margin-left: 8px;
  margin-block: 2px;
`
