import React from 'react'
import * as S from './styled'

function LoadingAnimation() {
  return (
    <S.LoadingContainer>
      <S.LoadingCircle />
      <S.LoadingCircle />
      <S.LoadingCircle />
    </S.LoadingContainer>
  )
}

export default LoadingAnimation
