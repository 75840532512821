import styled from 'styled-components'

export const Wrapper = styled.div`
  height: calc(100% - 220px);
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: calc(100% - 32px);
`

export const CardTitle = styled.div`
  width: 100%;
`

export const CardSection = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
  width: 100%;
`
