import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (config.formData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      }
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      }
    }
    if (token) {
      config.headers.Authorization = token
      config.headers['ngrok-skip-browser-warning'] = 'true'
    }

    return config
  },
  (err) => {
    throw new Error(err)
  }
)

export default api
