import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: ${COLORS.white_f8};
  font-size: 16px;
  font-weight: 500;
`

export const GameTimer = styled.p`
  width: 100%;
  text-align: center;
  padding: 12px;
  border-radius: 6px;
  background-color: ${COLORS.dark_43};
`
