import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px 16px;
  overflow-y: scroll;
  max-height: calc(100vh - 264px);
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`

export const CardsInfo = styled.div`
  overflow: hidden;
`

export const CardsQuantity = styled.p`
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.white_f8};
`

export const CardsQuantityNumber = styled.span`
  padding-left: 4px;
  color: ${COLORS.blue};
`
