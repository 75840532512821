import React from 'react'

export function YoutubeIcon() {
  return (
    <svg viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.792 0.414707C18.6521 0.645771 19.3325 1.32614 19.5635 2.18621C19.9872 3.75232 20 7.00007 20 7.00007C20 7.00007 20 10.2607 19.5764 11.8139C19.3453 12.674 18.665 13.3544 17.8049 13.5854C16.2516 14.0091 10 14.0091 10 14.0091C10 14.0091 3.74839 14.0091 2.19512 13.5854C1.33504 13.3544 0.654686 12.674 0.42362 11.8139C0 10.2478 0 7.00007 0 7.00007C0 7.00007 0 3.75232 0.410783 2.19905C0.641849 1.33897 1.32221 0.658613 2.18229 0.427549C3.73556 0.0039227 9.98716 -0.00891113 9.98716 -0.00891113C9.98716 -0.00891113 16.2388 -0.00891113 17.792 0.414707ZM13.1836 7.00008L7.99743 10.0039V3.99623L13.1836 7.00008Z'
        fill='#F8F8F8'
      />
    </svg>
  )
}
