import * as S from './styled'

export function GamesHeader({
  image,
  title,
  subtitle,
  description,
  imageSize,
}) {
  return (
    <S.GameHeader>
      <S.GameImage src={image} alt='game' size={imageSize} />
      <S.GameTitle>{title}</S.GameTitle>
      <S.GameSubtitle>{subtitle}</S.GameSubtitle>
      <S.GameDescription>{description}</S.GameDescription>
    </S.GameHeader>
  )
}
