import React from 'react'
import { CloseIcon } from 'assets/icons/Close'
import * as S from './styled'

function Modal({ children, open, onClose }) {
  return (
    <>
      {open && (
        <S.StyledOverlay onClick={onClose}>
          <S.StyledModalWrapper onClick={(e) => e.stopPropagation()}>
            <S.ModalCloseButton onClick={onClose}>
              <CloseIcon />
            </S.ModalCloseButton>
            <S.StyledModal>{children}</S.StyledModal>
          </S.StyledModalWrapper>
        </S.StyledOverlay>
      )}
    </>
  )
}

export default Modal
