import am from './am.png'
import au from './au.png'
import avatar from './avatar.png'
import background from './Background.jpeg'
import bottleCapOrange from './bottle-cap-orange.png'
import card from './card.png'
import ch from './ch.png'
import coin from './coin.png'
import de from './de.png'
import dogyCoin from './dogy-coin.png'
import eg from './eg.png'
import farmBig from './farm-big-size.png'
import farmSmall from './farm-small.png'
import flag from './flag.png'
import flyingMoney from './flying-money.png'
import fr from './fr.png'
import gamepad from './gamepad.png'
import gr from './gr.png'
import ind from './in.png'
import it from './it.png'
import jp from './jp.png'
import ksa from './ksa.png'
import millionaireCircle from './millionaireCircle.png'
import moneyBag from './money-bag.png'
import moneyMouth from './money-mouth.png'
import okx from './onboarding-okx.png'
import onboardingScreenOverlay from './onboarding-screen.png'
import voyager from './onboarding-voyager.png'
import x from './onboarding-x.png'
import personEmoji from './person-emoji.png'
import planeTicket from './plane-ticket.png'
import plane from './plane.png'
import puzzleGame from './puzzleGame.png'
import QR from './qr.png'
import quizGame from './quizGame.png'
import rok from './rok.png'
import roulette from './roulette.png'
import rouletteGame from './rouletteGame.png'
import ru from './ru.png'
import testCard from './test-card.jpeg'
import be from './be.png'
import uk from './uk.png'
import usa from './usa.png'

export const Image = {
  farmBig,
  farmSmall,
  onboardingScreenOverlay,
  personEmoji,
  card,
  background,
  testCard,
  QR,
  avatar,
  coin,
  okx,
  voyager,
  x,
  gamepad,
  quizGame,
  millionaireCircle,
  puzzleGame,
  flag,
  rouletteGame,
  roulette,
}

export const ROULETTE_IMAGES = {
  1: bottleCapOrange,
  2: dogyCoin,
  3: flyingMoney,
  4: moneyMouth,
  5: plane,
  6: moneyBag,
  7: planeTicket,
  8: coin,
}

export const COUNTRY_IMAGES = {
  1: de,
  2: usa,
  3: uk,
  4: jp,
  5: it,
  6: eg,
  7: fr,
  8: au,
  9: am,
  10: ch,
  11: be,
  12: rok,
  13: ksa,
  14: ind,
  15: ru,
  16: gr,
}
