import Farm from 'pages/Farm'
import Friends from 'pages/Friends'
import Home from 'pages/Home'
import Tasks from 'pages/Tasks'
import NotFound from 'pages/NotFound'
import { ROUTES } from 'utils/constants'
import Games from 'pages/Games'

export const routes = [
  {
    component: Home,
    path: ROUTES.home,
  },
  {
    component: Tasks,
    path: ROUTES.tasks,
  },
  {
    component: Farm,
    path: ROUTES.farm,
  },
  {
    component: Friends,
    path: ROUTES.friends,
  },
  {
    component: Games,
    path: ROUTES.games,
  },
  {
    component: NotFound,
    path: ROUTES.notFound,
  },
]
