import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const NotFoundContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`

export const NotFoundContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: calc(100% - 230px);
`

export const NotFoundTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 12px 0;
  color: ${COLORS.white};
`

export const NotFoundSubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${COLORS.white};
`

export const NotFoundActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 24px 0;
  gap: 24px;
  position: absolute;
  bottom: 94px;
`
