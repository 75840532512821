import { InstagramIcon } from 'assets/icons/InstagramIcon'
import { TelegramIcon } from 'assets/icons/TelegramIcon'
import { YoutubeIcon } from 'assets/icons/YoutubeIcon'
import VoyageIcon from 'assets/icons/VoyageIcon'
import React from 'react'
import { FacebookIcon } from 'assets/icons/FacebookIcon'
import { TwitterIcon } from 'assets/icons/TwitterIcon'
import { OkxIcon } from 'assets/icons/OkxIcon'
import StakeIcon from 'assets/icons/StakeIcon'
import PocketOptionIcon from 'assets/icons/PocketOption'

const iconMapper = {
  youtube: YoutubeIcon,
  telegram: TelegramIcon,
  game: TelegramIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  okx: OkxIcon,
  stake: StakeIcon,
  pocketOption: PocketOptionIcon,
  voyage: VoyageIcon,
}

function TaskIcon({ icon, size = '24px' }) {
  const IconComponent = iconMapper[icon] ?? VoyageIcon
  return (
    <span style={{ width: size, height: size }}>
      <IconComponent />
    </span>
  )
}

export default TaskIcon
