import styled from 'styled-components'

export const InviteButtonWrapper = styled.div`
  position: absolute;
  bottom: 4px;
  left: 0;
  width: -webkit-fill-available;
  padding-inline: 16px;
  margin-top: auto;
`
