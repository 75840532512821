import React, { memo } from 'react'
import Coin164Icon from 'assets/icons/Coin164Icon'
import * as S from './styled'

function BubbleAnimation({ isStartAnimation }) {
  const numberOfBubbles = 4 // 4 from left and 4 from right

  const bubbles = Array.from({ length: numberOfBubbles * 2 }, (_, index) => (
    <S.SmallBubble
      key={index}
      className={index < numberOfBubbles ? 'left' : 'right'}
      style={{
        '--random-top': Math.random(),
        '--random-duration': `${Math.random() * 1.5 + 1}s`,
        display: isStartAnimation ? 'block' : 'none',
      }}
    />
  ))

  return (
    <S.FarmBubbleAnimationContainer>
      <S.FarmImageWrapper>
        <Coin164Icon />
      </S.FarmImageWrapper>
      {isStartAnimation && bubbles}
    </S.FarmBubbleAnimationContainer>
  )
}

export default memo(BubbleAnimation)
