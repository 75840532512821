import { Image } from 'assets/images'
import { GamesHeader } from 'components/GamesHeader'
import RouletteWheel from 'components/RouletteWheel'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gamesSelector } from 'store/games/selectors'
import { ROUTES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function RouletteGame() {
  const { rouletteCanPlay } = useSelector(gamesSelector)
  const navigate = useNavigate()

  useEffect(() => {
    if (!rouletteCanPlay) {
      navigate(ROUTES.games)
    }
  }, [])
  return (
    <S.RouletteView>
      <GamesHeader
        image={Image.roulette}
        title='Spin the roulette'
        subtitle={`Earn ${formatCoin(1000)} - ${formatCoin(10000)} every day!`}
        imageSize='32px'
      />
      <RouletteWheel />
    </S.RouletteView>
  )
}
