import Button from 'common/Button'
import { GameCongratulationsDialog } from 'components/GameCongratulationsDialog'
import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesSelector } from 'store/games/selectors'
import { gamesActions } from 'store/games/slice'
import { userActions } from 'store/user/slice'
import { COLORS } from 'utils/constants'
import {
  calculatePrize,
  calculateSpins,
  shuffleImages,
  spinWheels,
} from 'utils/roulette'
import * as S from './styled'

function MyWheelComponent() {
  const [showWinnerDiv, setShowWinnerDiv] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [rewardAmount, setRewardAmount] = useState(0)
  const { rouletteCanPlay } = useSelector(gamesSelector)
  const dispatch = useDispatch()

  const animations = useRef([useRef(null), useRef(null), useRef(null)])
  const wheels = [useRef(null), useRef(null), useRef(null)]
  const wheelsArray = [
    {
      id: 1,
      ref: wheels[0],
      padding: 0,
      backgroundColor: COLORS.blue_002,
      shuffledImages: useMemo(() => shuffleImages(), []),
    },
    {
      id: 2,
      ref: wheels[1],
      padding: 68,
      backgroundColor: COLORS.blue_01,
      shuffledImages: useMemo(() => shuffleImages(), []),
    },
    {
      id: 3,
      ref: wheels[2],
      padding: 136,
      backgroundColor: COLORS.blue_004,
      shuffledImages: useMemo(() => shuffleImages(), []),
    },
  ]

  const handleSpin = () => {
    animations.current.forEach((animRef) => animRef.current?.cancel())
    setShowWinnerDiv(false)

    const spins = calculateSpins(wheels)
    const prize = calculatePrize(spins, wheelsArray)
    const maxDuration = Math.max(...spins.map((spin) => spin.duration))

    setRewardAmount(prize)
    dispatch(gamesActions.playRouletteGame({ prize }))
    dispatch(userActions.addTotalCoins(prize))

    setTimeout(() => {
      setShowWinnerDiv(true)
      setOpenModal(true)
    }, maxDuration)

    spinWheels(spins, wheels, animations)
  }

  return (
    <S.WheelsWrapper>
      <S.EnsureWheelsHeight>
        {wheelsArray.map((wheel) => (
          <S.EachWheelWrapper key={wheel.id} padding={wheel.padding}>
            {showWinnerDiv && wheel.id === 3 && (
              <S.WinnerDivWrapper>
                <S.WinnerDiv />
              </S.WinnerDivWrapper>
            )}
            <S.WheelOfFortune ref={wheel.ref}>
              <S.List>
                <S.EnsureSmoothBackground bgColor={wheel.backgroundColor} />
                {wheel.shuffledImages.map(([key, image]) => (
                  <S.ListItem bgColor={wheel.backgroundColor} key={key}>
                    <S.WheelImage src={image} alt={key} />
                  </S.ListItem>
                ))}
              </S.List>
            </S.WheelOfFortune>
          </S.EachWheelWrapper>
        ))}
      </S.EnsureWheelsHeight>
      <S.ButtonWrapper>
        <Button
          maxWidth='100%'
          onClick={handleSpin}
          text='Spin'
          isDisabled={!rouletteCanPlay}
        />
      </S.ButtonWrapper>
      <GameCongratulationsDialog
        closeDialog={() => setOpenModal(false)}
        reward={rewardAmount}
        isOpen={openModal}
        success={openModal}
      />
    </S.WheelsWrapper>
  )
}

export default MyWheelComponent
