import React from 'react'
import { CloseIcon } from 'assets/icons/Close'
import * as S from './styled'

export function Dialog({ title, isOpen, closeDialog, children, top }) {
  if (!isOpen) return null

  return (
    <S.DialogWrapper top={top}>
      <S.DialogContent top={top}>
        <S.DialogHeader>
          <S.HeaderLeftWrapper />
          <S.DialogTitle>{title}</S.DialogTitle>
          <CloseIcon onClick={closeDialog} />
        </S.DialogHeader>
        {children}
      </S.DialogContent>
    </S.DialogWrapper>
  )
}
