import { Image } from 'assets/images'
import styled, { keyframes } from 'styled-components'
import { COLORS } from 'utils/constants'

const scaleUp = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const scaleUpImage = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const scaleUpAmount = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
`

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #11111166;
  z-index: 999;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupContainer = styled.div`
  background-image: url(${Image.card});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 19/17;
  display: flex;
  margin-inline: 16px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  animation: ${scaleUp} 0.5s ease forwards;
`

export const ContentWrapper = styled.div`
  padding-inline: 16px;
  padding-top: 16px;
  max-width: 100%;
  position: relative;
  height: min-content;
`

export const ContentImage = styled.img`
  max-height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  opacity: 0;
  animation: ${scaleUpImage} 0.5s ease forwards;
  animation-delay: 0.3s;
`

export const AmountContainer = styled.div`
  max-width: 112px;
  max-height: min-content;
  padding: 12px 8px;
  position: absolute;
  left: 50%;
  top: calc(50% - 16px);
  transform: translate(-50%, -30%);
  background: ${COLORS.black_28};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0;
  animation: ${scaleUpAmount} 0.5s ease forwards;
  animation-delay: 0.8s;
`

export const QRWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 5px 0 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 20%;
`

export const QRImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

export const AmountIcon = styled.img`
  width: 32px;
  height: 32px;
`

export const Amount = styled.p`
  font-size: 24px;
  line-height: 40px;
  white-space: nowrap;
  color: ${COLORS.white_f8};
`
