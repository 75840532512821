import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from 'utils/constants'

export const Menu = styled.nav`
  display: flex;
  justify-content: space-around;
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  padding: 10px 0 54px 0;
`

export const MenuItem = styled(Link)`
  gap: 8px;
  height: 50px;
  color: ${({ color }) => color || COLORS.white};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`
