import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const PuzzleView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 24px;
  padding-bottom: 24px;
  ${overflowScroll}
`

export const PuzzleCountriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  gap: 10px;
`

export const PuzzleAnswersWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  padding: 6px;
  border-radius: 8px;
  background: linear-gradient(90deg, #2b81eb 0%, #184985 100%);
`

export const PuzzleOptionsWrapper = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  padding: 6px;
  border-radius: 8px;
  background-color: ${COLORS.dark_43};
`

export const PuzzleOptionContainer = styled.div`
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  aspect-ratio: 1/1;
  border-radius: 6px;
  padding: 14px;
  background-color: ${COLORS.black_28};
  opacity: ${({ active }) => (active ? 0.5 : 1)};
`

export const PuzzleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
