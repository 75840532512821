import React from 'react'

export function FacebookIcon() {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 21.8883C18.3113 21.1645 22 17.013 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.013 5.68874 21.1645 10.5 21.8883L10.5 15H9C8.17157 15 7.5 14.3284 7.5 13.5C7.5 12.6716 8.17157 12 9 12H10.5V10C10.5 8.067 12.067 6.5 14 6.5H14.5C15.3284 6.5 16 7.17157 16 8C16 8.82843 15.3284 9.5 14.5 9.5H14C13.7239 9.5 13.5 9.72386 13.5 10V12H15C15.8284 12 16.5 12.6716 16.5 13.5C16.5 14.3284 15.8284 15 15 15H13.5L13.5 21.8883Z'
        fill='#F8F8F8'
      />
    </svg>
  )
}
