import React from 'react'
import Button from 'common/Button'
import { QRCodeCanvas } from 'qrcode.react'
import { COLORS } from 'utils/constants'
import CheckIcon from 'assets/icons/CheckIcon'
import * as S from './styled'

export function InviteFriendDialogContent({
  handleSendInTelegram,
  onCopyLinkClick,
  isLoading,
  user,
}) {
  return (
    <S.InviteFriendDialogContent>
      <S.QrCode>
        <QRCodeCanvas
          value={`https://t.me/${process.env.REACT_APP_TG_BOT_USERNAME}/?start=${user?.telegramId}`}
          size={254}
          bgColor={COLORS.white_f8}
          fgColor={COLORS.black}
          level='H'
        />
      </S.QrCode>
      <Button
        text='Send in Telegram'
        maxWidth='100%'
        onClick={handleSendInTelegram}
      />
      <Button
        leftIcon={isLoading && <CheckIcon />}
        text={!isLoading ? 'Copy link' : ''}
        maxWidth='100%'
        bgColor='transparent'
        onClick={onCopyLinkClick}
      />
    </S.InviteFriendDialogContent>
  )
}
