import styled from 'styled-components'
import { COLORS } from '../../utils/constants'

const StyledSingleText = styled.p`
  color: ${({ textColor }) => textColor || COLORS.white_f8};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`

export default StyledSingleText
