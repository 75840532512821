import { createAsyncThunk } from '@reduxjs/toolkit'
import { cardActions, incrementAvailableCards } from 'store/card/slice'
import { userActions } from 'store/user/slice'
import { tasksApi } from './services'

export const getTasks = createAsyncThunk(
  'tasks/getTasks',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.getTasks()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const completeTask = createAsyncThunk(
  'tasks/completeTask',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.completeTask(body)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const completeTasks = createAsyncThunk(
  'tasks/completeTasks',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await tasksApi.completeTasks()
      dispatch(incrementAvailableCards(data.cardsCount))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDailyTasks = createAsyncThunk(
  'tasks/getDailyTasks',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.getDailyTasks()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const completeDailyTask = createAsyncThunk(
  'tasks/completeDailyTask',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.completeDailyTask(body)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const gainDailyTask = createAsyncThunk(
  'tasks/gainDailyTask',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await tasksApi.gainDailyTask(body)
      dispatch(userActions.addTotalCoins(data.coins))
      dispatch(cardActions.incrementAvailableCards(data.cardsCount))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getSocialTasks = createAsyncThunk(
  'tasks/getSocialTasks',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.getSocialTasks()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const completeSocialTask = createAsyncThunk(
  'tasks/completeSocialTask',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await tasksApi.completeSocialTask(body)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const gainSocialTask = createAsyncThunk(
  'tasks/gainSocialTask',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await tasksApi.gainSocialTask(body)
      dispatch(userActions.addTotalCoins(data.reward))
      dispatch(cardActions.incrementAvailableCards(data.cardsCount))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
