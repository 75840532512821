import React from 'react'

export function TelegramIcon() {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.30615 10.9574C8.40641 8.84056 11.8074 7.445 13.5091 6.77074C18.3677 4.84558 19.3773 4.51116 20.0354 4.50012C20.1801 4.49769 20.5037 4.53186 20.7133 4.69389C20.8903 4.83071 20.939 5.01553 20.9623 5.14524C20.9856 5.27496 21.0146 5.57046 20.9916 5.80136C20.7283 8.43675 19.589 14.8322 19.0094 17.7838C18.7642 19.0328 18.2813 19.4516 17.8138 19.4926C16.7978 19.5816 16.0263 18.8529 15.0422 18.2384C13.5024 17.2769 12.6325 16.6783 11.1378 15.74C9.4105 14.6556 10.5303 14.0596 11.5147 13.0856C11.7723 12.8307 16.2488 8.95188 16.3354 8.60001C16.3463 8.55601 16.3563 8.39197 16.254 8.30535C16.1517 8.21873 16.0007 8.24835 15.8918 8.27191C15.7373 8.3053 13.2775 9.85418 8.51212 12.9185C7.81389 13.3753 7.18145 13.5978 6.61481 13.5862C5.99014 13.5733 4.78851 13.2497 3.89523 12.9731C2.79958 12.6338 1.92878 12.4544 2.0046 11.8782C2.0441 11.5781 2.47795 11.2712 3.30615 10.9574Z'
        fill='#F8F8F8'
      />
    </svg>
  )
}
