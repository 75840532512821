import React from 'react'
import * as S from './styled'

function FormInput({ placeholder, disabled, value, onChange, ...settings }) {
  return (
    <S.StyleInputWrapper>
      <S.StyledInput
        {...settings}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </S.StyleInputWrapper>
  )
}

export default FormInput
