import styled, { keyframes } from 'styled-components'
import { COLORS } from 'utils/constants'

// Animation for sliding the dialog from bottom to top
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const DialogWrapper = styled.div`
  font-family: Poppins;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: ${({ top }) => (top ? 'unset' : 'flex-end')};
  justify-content: center;
  z-index: 999;
`

export const DialogContent = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 430px;
  padding: ${({ top }) => (top ? '40px 16px 16px' : '16px 16px 54px')};
  border-radius: ${({ top }) => (top ? '0 0 24px 24px' : '24px 24px 0 0')};
  background: ${COLORS.black_28};
  animation: ${({ top }) => (top ? slideDown : slideUp)} 0.3s ease-out;
  position: relative;
`

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DialogTitle = styled.h2`
  color: ${COLORS.white};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`

export const HeaderLeftWrapper = styled.div`
  min-width: 24px;
`
