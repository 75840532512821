import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import {
  getUserGames,
  playPuzzleGame,
  playQuizGame,
  playRouletteGame,
} from './actions'

const initialState = {
  quiz: {
    canPlay: false,
    question: {
      country: null,
      capital: null,
    },
    cities: null,
    reward: null,
    cardsCount: null,
  },
  puzzle: {
    canPlay: false,
    queue: null,
    reward: null,
    cardsCount: null,
  },
  rouletteCanPlay: false,
  isPuzzleGameAnswerRight: null,
  isQuizGameAnswerRight: null,
  loadGamesStatus: LOADING_STATUSES.idle,
  playQuizGameStatus: LOADING_STATUSES.idle,
  playPuzzleGameStatus: LOADING_STATUSES.idle,
  playRouletteGameStatus: LOADING_STATUSES.idle,
}

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserGames.pending, (state) => {
      state.loadGamesStatus = LOADING_STATUSES.pending
    })
    addCase(getUserGames.fulfilled, (state, { payload }) => {
      state.loadGamesStatus = LOADING_STATUSES.succeeded
      state.rouletteCanPlay = payload.roulette?.canPlay
      state.quiz = payload.question
      state.puzzle = payload.puzzle
      state.isPuzzleGameAnswerRight = null
      state.isQuizGameAnswerRight = null
      state.playQuizGameStatus = LOADING_STATUSES.idle
      state.playPuzzleGameStatus = LOADING_STATUSES.idle
      state.playRouletteGameStatus = LOADING_STATUSES.idle
    })
    addCase(getUserGames.rejected, (state) => {
      state.loadGamesStatus = LOADING_STATUSES.failed
    })
    addCase(playQuizGame.pending, (state) => {
      state.playQuizGameStatus = LOADING_STATUSES.pending
    })
    addCase(playQuizGame.fulfilled, (state, { payload }) => {
      state.playQuizGameStatus = LOADING_STATUSES.succeeded
      state.isQuizGameAnswerRight = payload.success
      state.quiz.canPlay = false
    })
    addCase(playQuizGame.rejected, (state) => {
      state.playQuizGameStatus = LOADING_STATUSES.failed
    })
    addCase(playPuzzleGame.pending, (state) => {
      state.playPuzzleGameStatus = LOADING_STATUSES.pending
    })
    addCase(playPuzzleGame.fulfilled, (state, { payload }) => {
      state.playPuzzleGameStatus = LOADING_STATUSES.succeeded
      state.isPuzzleGameAnswerRight = payload.success
      state.puzzle.canPlay = false
    })
    addCase(playPuzzleGame.rejected, (state) => {
      state.playPuzzleGameStatus = LOADING_STATUSES.failed
    })
    addCase(playRouletteGame.pending, (state) => {
      state.playRouletteGameStatus = LOADING_STATUSES.pending
      state.rouletteCanPlay = false
    })
    addCase(playRouletteGame.fulfilled, (state) => {
      state.playRouletteGameStatus = LOADING_STATUSES.succeeded
      state.rouletteCanPlay = false
    })
    addCase(playRouletteGame.rejected, (state) => {
      state.playRouletteGameStatus = LOADING_STATUSES.failed
    })
  },
})

export const gamesReducer = gamesSlice.reducer

export const gamesActions = {
  ...gamesSlice.actions,
  getUserGames,
  playQuizGame,
  playPuzzleGame,
  playRouletteGame,
}
