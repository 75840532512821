import { GamesList } from 'components/GamesList'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserGames } from 'store/games/actions'
import { gamesSelector } from 'store/games/selectors'

function Games() {
  const games = useSelector(gamesSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserGames())
  }, [])

  return (
    <GamesList
      quizGame={games.quiz}
      puzzleGame={games.puzzle}
      rouletteGame={games.rouletteCanPlay}
    />
  )
}

export default Games
