import React from 'react'
import Button from 'common/Button'
import { openLink } from '@telegram-apps/sdk-react'
import * as S from './styled'

export function AboutUsContent() {
  const roadmap = [
    {
      period: '2024/Q4',
      steps: [
        'Official Project Launch',
        'Airdrop Distribution Based on Bot Results',
        'Token Listing on Leading Cryptocurrency Exchanges',
      ],
    },
    {
      period: '2025/Q1',
      steps: [
        'Integration of Booking System via Telegram',
        'Launch of Airline and Hotel Reservation System',
        'Introduction of Trading Platform',
        'Decentralized Exchange (DEX) Integration on Telegram',
      ],
    },
    {
      period: '2025/Q2',
      steps: [
        'Inaugural Flight of Era Voyager: The World’s First Cryptocurrency-Only Aircraft',
        'Development and Launch of Cryptocurrency Payment System',
        'Expansion to Global Availability',
      ],
    },
  ]

  const aboutTexts = [
    'Era Voyage is the result of over three years of dedicated planning to transform the travel industry. We offer a seamless platform that integrates hotel, restaurant, and flight bookings, all while allowing users to pay with cryptocurrency.',
    `Our innovative system includes NFT-based bookings, giving each reservation a unique value.
Our trading platform lets travelers resell bookings for a potential profit, adding flexibility and value to every trip. Additionally, we're launching crypto payments globally, starting with Era Voyager, where tickets will be purchased exclusively with cryptocurrency. Join us in shaping the future of travel.`,
  ]

  const onPartnershipClick = () => {
    openLink('https://forms.gle/aBTqJRxoBWbcV3Lo7')
  }

  const onAmbassadorClick = () => {
    openLink('https://forms.gle/LUv1p5oNNXAC5ndV8')
  }

  return (
    <S.AboutUsContainer>
      <S.PartContainer>
        <S.PartTitle> Project Roadmap:</S.PartTitle>
        <S.StepsContainer>
          {roadmap.map((part) => {
            return (
              <div key={part.period}>
                <S.StepTitle>{part.period}</S.StepTitle>
                <ul>
                  {part.steps.map((step) => (
                    <S.Step key={step}>{step}</S.Step>
                  ))}
                </ul>
              </div>
            )
          })}
        </S.StepsContainer>
      </S.PartContainer>
      <S.PartContainer>
        <S.PartTitle> About Voyager: </S.PartTitle>
        <S.StepsContainer>
          {aboutTexts.map((text) => (
            <S.AboutText key={text}>{text}</S.AboutText>
          ))}
        </S.StepsContainer>
      </S.PartContainer>
      <S.LinksPartContainer>
        <Button
          onClick={onPartnershipClick}
          text='For partnership'
          maxWidth='100%'
        />
        <Button
          onClick={onAmbassadorClick}
          text='For ambassador'
          bgColor='transparent'
          maxWidth='100%'
        />
      </S.LinksPartContainer>
    </S.AboutUsContainer>
  )
}
