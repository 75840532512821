import React from 'react'
import Button from 'common/Button'
import { COLORS } from 'utils/constants'
import { TotalCoin } from 'assets/icons/TotalCoin'
import { TotalFriends } from 'assets/icons/TotalFriends'
import { TotalFarm } from 'assets/icons/TotalFarm'
import { TotalCards } from 'assets/icons/TotalCards'
import { formatCoin } from 'utils/format-coin'
import { Image } from 'assets/images'
import { ButtonText } from 'components/NavBar/styled'
import * as S from './styled'

export function PersonalDetailsContent({
  user,
  friendsCount,
  userFarmedCoins,
  userTotalCoins,
  userTotalCards,
}) {
  const personalAnalytics = [
    {
      title: 'Total coins',
      value: formatCoin(userTotalCoins),
      icon: TotalCoin,
      isMoney: true,
    },
    {
      title: 'Total friends',
      value: friendsCount,
      icon: TotalFriends,
      isMoney: false,
    },

    {
      title: 'Totally farmed',
      value: formatCoin(userFarmedCoins),
      icon: TotalFarm,
      isMoney: true,
    },
    {
      title: 'Total cards',
      value: userTotalCards,
      icon: TotalCards,
      isMoney: false,
    },
  ]

  return (
    <S.PersonalDetailsDialogContent>
      <Button
        text={<ButtonText maxWidth='120'>{user?.name}</ButtonText>}
        leftIcon={<S.Icon src={user?.avatar} />}
        bgColor={COLORS.black_28}
        borderColor={COLORS.gray_3B}
        padding='8px 8px 8px 6px'
      />
      <S.PersonalDetailsContainer>
        {personalAnalytics.map((item) => (
          <S.AnalyticsItem key={item.title}>
            <S.IconWrapper>
              <S.IconArea>
                <item.icon />
              </S.IconArea>
              <S.Title>{item.title}</S.Title>
            </S.IconWrapper>
            <S.Value>
              {item.value}
              {item.isMoney ? (
                <S.CoinIcon src={Image.coin} />
              ) : (
                <S.RightSpace />
              )}
            </S.Value>
          </S.AnalyticsItem>
        ))}
      </S.PersonalDetailsContainer>
    </S.PersonalDetailsDialogContent>
  )
}
