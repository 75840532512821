import { API } from 'configs/api'
import axios from 'configs/axios'

export const tasksApi = {
  getTasks: () => {
    return axios.get(API.tasks)
  },

  completeTask: (body) => {
    return axios.post(API.completeTask, body)
  },

  completeTasks: () => {
    return axios.post(API.completeTasks)
  },

  getDailyTasks: () => {
    return axios.get(API.dailyTasks)
  },

  completeDailyTask: (body) => {
    return axios.post(API.completeDailyTask, body)
  },

  gainDailyTask: (body) => {
    return axios.post(API.gainDailyTask, body)
  },

  getSocialTasks: () => {
    return axios.get(API.socialTasks)
  },

  completeSocialTask: (body) => {
    return axios.post(API.completeSocialTask, body)
  },

  gainSocialTask: (body) => {
    return axios.post(API.gainSocialTask, body)
  },
}
