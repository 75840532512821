import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { getStore } from 'store'
import { BrowserRouter as Router } from 'react-router-dom'
import { StyledComponentsProvider } from 'providers/StyledComponentsProvider'
import App from './App'
import './main.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StyledComponentsProvider>
    <Provider store={getStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </StyledComponentsProvider>
)
