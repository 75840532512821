import { API } from 'configs/api'
import axios from 'configs/axios'

export const friendsApi = {
  getFriends: () => {
    return axios.get(API.friends)
  },
  friendsCollect: () => {
    return axios.get(API.friendsCollect)
  },
}
