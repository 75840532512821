import { useEffect, useState } from 'react'
import { formatTime } from 'utils/format-time'
import * as S from './styled'

function calculateTimeLeft(targetDate) {
  const availableAtTime = new Date(targetDate).getTime()
  const timeRemaining = availableAtTime - Date.now()

  return timeRemaining
}

export function GameTimeLeft({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(targetDate))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [targetDate])

  return (
    <S.TimerWrapper>
      <p>The next game starts in</p>
      <S.GameTimer>{formatTime(timeLeft)}</S.GameTimer>
    </S.TimerWrapper>
  )
}
