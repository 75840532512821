import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'

import { COLORS } from 'utils/constants'

export const TasksView = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 196px);
  padding-inline: 16px;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  height: fit-content;
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  margin: 24px 0 8px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${COLORS.white_f8};
`

export const Text = styled.p`
  font-size: 14px;
  margin: 0px 0 16px 0;
  color: ${COLORS.blue};
`
export const TabsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 24px 0 16px;
`

export const Tab = styled.button`
  background-color: transparent;
  border: none;
`

export const TasksList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 12px;
  ${overflowScroll}

  &::-webkit-scrollbar {
    display: none;
  }
`

export const SingleTask = styled.div`
  background-color: ${COLORS.black_28};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.white};
  border: 1px solid;
  border-color: ${({ special }) => (special ? COLORS.blue : COLORS.gray_3B)};
  border-radius: 6px;
  padding: 8px 8px 8px 6px;
`

export const TaskInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TaskText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const TaskPoints = styled.div`
  display: flex;
  gap: 4px;
`

export const CongratulationsWrapper = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CongratulationsTop = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const CongratulationsTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.white_f8};
`
export const TotalCoinInfo = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CoinValue = styled.span`
  gap: 4px;
  display: flex;
  align-items: center;
`

export const CoinCardLabel = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${COLORS.blue};
`

export const CoinLabel = styled.h2`
  gap: 4px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: ${COLORS.white_f8};
`

export const ReceivedCoin = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.gray};
`

export const TaskCardInvitationRewardCard = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-right: 8px;
  color: ${COLORS.blue};
`
