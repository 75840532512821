import FarmForm from 'components/FarmForm'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { farmActions } from 'store/farm/slice'

function Farm() {
  const isChecked = useSelector((state) => state.farm.isChecked)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isChecked) {
      dispatch(farmActions.checkFarmingStatus())
    }
  }, [])

  if (!isChecked) {
    return null
  }

  return <FarmForm />
}

export default Farm
