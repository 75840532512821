import { createAsyncThunk } from '@reduxjs/toolkit'
import { userActions } from 'store/user/slice'
import { farmApi } from './services'

export const checkFarmingStatus = createAsyncThunk(
  'farm/checkFarmingStatus',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await farmApi.checkFarmingStatus()

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const startFarming = createAsyncThunk(
  'farm/startFarming',
  async ({ timeLeft }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await farmApi.startFarming()
      if (timeLeft === 0) {
        dispatch(userActions.addFarmedCoins(data.coinsPerSession))
      }
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
