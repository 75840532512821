import React from 'react'

import Button from 'common/Button'
import NotFoundIcon from 'assets/icons/NotFoundIcon'
import * as S from './styled'

function NotFoundDialogContent() {
  return (
    <S.NotFoundContainer>
      <S.NotFoundContentWrapper>
        <NotFoundIcon />
        <S.NotFoundTitle>Oops!</S.NotFoundTitle>
        <S.NotFoundSubTitle>Something went wrong.</S.NotFoundSubTitle>
      </S.NotFoundContentWrapper>
      <S.NotFoundActionBlock>
        <Button text='Retry again' maxWidth='100%' onClick={() => {}} />
        <Button text='Go back' maxWidth='100%' bgColor='transparent' />
      </S.NotFoundActionBlock>
    </S.NotFoundContainer>
  )
}

export default NotFoundDialogContent
