import React from 'react'
import * as S from './styled'

export function ScreenDialog({ isOpen, children }) {
  if (!isOpen) return null

  return (
    <S.DialogWrapper>
      <S.DialogContent>{children}</S.DialogContent>
    </S.DialogWrapper>
  )
}
