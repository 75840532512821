import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const CongratulationsWrapper = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CongratulationsTop = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CongratulationsTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.white_f8};
`
export const RewardInfo = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const RewardTitle = styled.div`
  font-size: 14px;
`

export const RewardSubtitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.gray};
`
