import axios from 'configs/axios'
import { API } from 'configs/api'

export const gamesApi = {
  getGames: () => {
    return axios.get(API.getGames)
  },

  playQuizGame: (body) => {
    return axios.post(API.playQuizGame, body)
  },

  playPuzzleGame: (body) => {
    return axios.post(API.playPuzzleGame, body)
  },

  playRouletteGame: (body) => {
    return axios.post(API.playRouletteGame, body)
  },
}
