import FarmsIcon from 'assets/icons/Farms'
import FriendsIcon from 'assets/icons/Friends'
import GameIcon from 'assets/icons/GameIcon'
import HomeIcon from 'assets/icons/Home'
import TasksIcon from 'assets/icons/Tasks'
import * as S from 'components/BottomMenu/styled'
import { useLocation } from 'react-router-dom'
import { COLORS, ROUTES } from 'utils/constants'

const menuItems = [
  { route: ROUTES.home, label: 'Home', Icon: HomeIcon },
  { route: ROUTES.games, label: 'Games', Icon: GameIcon },
  { route: ROUTES.tasks, label: 'Tasks', Icon: TasksIcon },
  { route: ROUTES.farm, label: 'Farm', Icon: FarmsIcon },
  { route: ROUTES.friends, label: 'Friends', Icon: FriendsIcon },
]

function BottomMenu() {
  const location = useLocation()

  return (
    <S.Menu>
      {menuItems.map(({ route, label, Icon }) => (
        <S.MenuItem
          key={route}
          to={route}
          color={location.pathname === route ? COLORS.blue : COLORS.white}
        >
          <Icon
            color={location.pathname === route ? COLORS.blue : COLORS.white}
          />
          <span>{label}</span>
        </S.MenuItem>
      ))}
    </S.Menu>
  )
}

export default BottomMenu
