import React, { useRef } from 'react'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { Image } from 'assets/images'
import { formatCoin } from 'utils/format-coin'
import * as S from './styled'

export function CardPopup({ isOpen, card, closeModal }) {
  const popupRef = useRef(null)

  useOutsideClick(popupRef, closeModal)

  if (!isOpen) return null

  return (
    <S.PopupBackground>
      <S.PopupContainer ref={popupRef}>
        <S.ContentWrapper>
          <S.ContentImage src={card.imageUrl} />
          <S.AmountContainer>
            <S.AmountIcon src={Image.farmBig} />
            <S.Amount>{formatCoin(card.coins)}</S.Amount>
          </S.AmountContainer>
        </S.ContentWrapper>
        <S.QRWrapper>
          <S.QRImage src={Image.QR} />
        </S.QRWrapper>
      </S.PopupContainer>
    </S.PopupBackground>
  )
}
