import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'

const initialState = {
  cardsData: [],
  cardStatus: LOADING_STATUSES.idle,
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: () => {},
})

export const homeReducer = homeSlice.reducer

export const homeActions = {
  ...homeSlice.actions,
}
