import { createAsyncThunk } from '@reduxjs/toolkit'
import { cardApi } from './services'

export const getUsersCards = createAsyncThunk(
  'users/cards',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await cardApi.getCards()

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getUsersCardById = createAsyncThunk(
  'users/card-open',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await cardApi.getCardById({ cardId: id })

      return data
    } catch (error) {
      if (error?.status === 400 && error?.message) {
        const message = error?.response?.data?.message || error.message
        return { error: true, message, id }
      }

      return rejectWithValue(error)
    }
  }
)

export const incrementAvailableCardsByOne = createAsyncThunk(
  'users/available-increment',
  async (_, { rejectWithValue }) => {
    try {
      await cardApi.incrementAvailableCardsByOne()
      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
