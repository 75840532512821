import { overflowScroll } from 'common/SharedStyles/styled'
import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const GamesView = styled.div`
  color: ${COLORS.white_f8};
  display: flex;
  flex-direction: column;
  height: ${({ isFullHeight }) =>
    isFullHeight ? '100%' : 'calc(100% - 196px)'};
  align-items: center;
  padding: 16px;
  gap: 32px;
`

export const GamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  ${overflowScroll}
`

export const GamesListWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`
