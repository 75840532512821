import React from 'react'
import CoinImage from 'common/CoinImage'
import * as S from './styled'

export function TotalERVCoin({ totalCoin }) {
  return (
    <S.Container>
      <CoinImage size='24' />
      <S.CoinText>ERV: {totalCoin}</S.CoinText>
    </S.Container>
  )
}
