import { HelpIcon } from 'assets/icons/HelpIcon'
import { Wallet } from 'assets/icons/wallet'
import Button from 'common/Button'
import CoinImage from 'common/CoinImage'
import { Dialog } from 'common/Dialog'
import Tooltip from 'common/Tooltip'
import { AboutUsContent } from 'components/DialogsContent/AboutUsContent'
import { PersonalDetailsContent } from 'components/DialogsContent/PersonalDetailsContent'
import * as S from 'components/NavBar/styled'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { cardSelector } from 'store/card/selectors'
import { userSelector } from 'store/user/selectors'
import { COLORS, ROUTES } from 'utils/constants'
import { formatCoin } from 'utils/format-coin'

function NavBar() {
  const { userTotalCoins, userFarmedCoins, invitedFriendsCount, user } =
    useSelector(userSelector)
  const { totalCards } = useSelector(cardSelector)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false)
  const { pathname } = useLocation()

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const openPersonalDetailsDialog = () => {
    setIsDialogOpen(true)
  }

  const closeAboutDialog = () => {
    setIsAboutDialogOpen(false)
  }

  const openAboutDetailsDialog = () => {
    setIsAboutDialogOpen(true)
  }

  const coins = formatCoin(userTotalCoins)
  const coinsText = coins === '0' ? <S.NoCoin>{coins}</S.NoCoin> : coins

  return (
    <S.NavContainer>
      <Button
        text={<S.ButtonText maxWidth='120'> {user?.name}</S.ButtonText>}
        leftIcon={<S.Icon src={user?.avatar} />}
        bgColor={COLORS.black_28}
        borderColor={COLORS.gray_3B}
        onClick={openPersonalDetailsDialog}
        padding='8px 8px 8px 6px'
      />
      <S.RightPartWrapper>
        {pathname !== ROUTES.home && (
          <Button
            leftIcon={<CoinImage size='24' />}
            text={coinsText}
            bgColor={COLORS.black_28}
            borderColor={COLORS.gray_3B}
            padding='8px 8px 8px 6px'
          />
        )}
        <Tooltip tooltipContent='Soon'>
          <Wallet />
        </Tooltip>
        <Button
          padding='0'
          bgColor='transparent'
          borderColor='transparent'
          onClick={openAboutDetailsDialog}
          leftIcon={<HelpIcon />}
        />
      </S.RightPartWrapper>
      <Dialog
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        title='Personal analytics'
      >
        <PersonalDetailsContent
          user={user}
          userTotalCoins={userTotalCoins}
          userTotalCards={totalCards}
          userFarmedCoins={userFarmedCoins}
          friendsCount={invitedFriendsCount}
        />
      </Dialog>
      <Dialog
        isOpen={isAboutDialogOpen}
        closeDialog={closeAboutDialog}
        title='About Us'
      >
        <AboutUsContent />
      </Dialog>
    </S.NavContainer>
  )
}

export default NavBar
