import React, { useMemo } from 'react'
import { Image } from 'assets/images'
import { Timer } from 'components/Timer'
import Button from 'common/Button'
import * as S from './styled'

export function Card({
  card,
  disabled,
  availableCards,
  timeRemaining,
  openCard,
}) {
  const isAvailable = useMemo(() => {
    return availableCards === 0 && timeRemaining > 0
  }, [availableCards, timeRemaining])

  const handleOpenButtonClick = () => {
    openCard(card)
  }

  return (
    <S.CardContainer>
      <S.ContentWrapper>
        <S.ContentImageWrapper>
          <S.ContentImage src={card.imageUrl} />
        </S.ContentImageWrapper>
        <S.AmountContainer>
          {isAvailable ? (
            <Timer timeRemaining={timeRemaining} />
          ) : (
            <Button
              isDisabled={disabled}
              text='Open'
              onClick={handleOpenButtonClick}
              maxWidth='67px'
              maxHeight='42px'
            />
          )}
        </S.AmountContainer>
      </S.ContentWrapper>
      <S.QRWrapper>
        <S.QRImage src={Image.QR} />
      </S.QRWrapper>
    </S.CardContainer>
  )
}
