import React, { useEffect, useState } from 'react'
import VoyageIcon from 'assets/icons/VoyageIcon'
import Button from 'common/Button'
import BubbleAnimation from 'components/FarmBubbleAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { formatTime } from 'utils/format-time'
import { farmActions } from 'store/farm/slice'
import { formatCoin } from 'utils/format-coin'
import { userSelector } from 'store/user/selectors'
import { farmSelector } from 'store/farm/selectors'
import { LOADING_STATUSES, TASK_BUTTONS_STATES } from 'utils/constants'
import Skeleton from 'common/Skeleton'
import * as S from './styled'

function FarmForm() {
  const { coinsPerSecond, timeLeft, farmedCoins, getFarmingStatus } =
    useSelector(farmSelector)
  const { userFarmedCoins } = useSelector(userSelector)
  const [loading, setLoading] = useState(true)

  const [remainingTime, setRemainingTime] = useState(timeLeft)
  const [coins, setCoins] = useState(farmedCoins)

  const dispatch = useDispatch()

  const startFarming = () => {
    dispatch(farmActions.startFarming({ timeLeft }))
  }

  useEffect(() => {
    setLoading(true)

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (getFarmingStatus === LOADING_STATUSES.fulfilled) {
      setCoins(farmedCoins)
    }
  }, [getFarmingStatus])

  useEffect(() => {
    if (timeLeft) {
      dispatch(farmActions.checkFarmingStatus())
    }
  }, [])

  useEffect(() => {
    setRemainingTime(timeLeft)

    if (timeLeft) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timer)
            dispatch(farmActions.checkFarmingStatus())
            return 0
          }

          setCoins((prev) => prev + coinsPerSecond)
          return prevTime - 1000
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [timeLeft])

  return (
    <S.FarmView>
      <S.FarmHeading>
        <S.FarmTile>Farm more tokens</S.FarmTile>
        <S.FarmDescription>
          Rise higher with token Farming! <br /> Maximize your tokens and keep
          the farm hopping
        </S.FarmDescription>
      </S.FarmHeading>
      <BubbleAnimation isStartAnimation={!!timeLeft} />
      <S.TotalCoinInfo>
        <S.CoinLabel>Totally farmed:</S.CoinLabel>
        <S.CoinValue>
          <VoyageIcon />
          ERV: {formatCoin(userFarmedCoins)}
        </S.CoinValue>
      </S.TotalCoinInfo>
      {loading ? (
        <S.FormStartButton>
          <Skeleton.Line width='100%' height='36px' />
        </S.FormStartButton>
      ) : !timeLeft ? (
        <S.FormStartButton>
          <Button
            text={
              timeLeft === 0
                ? TASK_BUTTONS_STATES.collect
                : TASK_BUTTONS_STATES.start
            }
            onClick={startFarming}
            maxWidth='100%'
          />
        </S.FormStartButton>
      ) : (
        <S.FormFarmingInformation>
          <S.Timer>
            Farming: <VoyageIcon />
            {Number(formatCoin(Number(coins))).toFixed(3)}
          </S.Timer>
          <S.Timer>{formatTime(remainingTime)}</S.Timer>
        </S.FormFarmingInformation>
      )}
    </S.FarmView>
  )
}

export default FarmForm
