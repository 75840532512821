import { createAsyncThunk } from '@reduxjs/toolkit'
import { userActions } from 'store/user/slice'
import { cardActions } from 'store/card/slice'
import { gamesApi } from './services'

export const getUserGames = createAsyncThunk(
  'games/get-games',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await gamesApi.getGames()
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const playQuizGame = createAsyncThunk(
  'games/quiz',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await gamesApi.playQuizGame(body)
      dispatch(userActions.addTotalCoins(data.reward))
      dispatch(cardActions.incrementAvailableCards(data.cardsCount))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const playPuzzleGame = createAsyncThunk(
  'games/puzzle',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await gamesApi.playPuzzleGame(body)
      dispatch(userActions.addTotalCoins(data.reward))
      dispatch(cardActions.incrementAvailableCards(data.cardsCount))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const playRouletteGame = createAsyncThunk(
  'games/roulette',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await gamesApi.playRouletteGame(body)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
