import React, { useState } from 'react'
import * as S from './styled'

function Tooltip({ children, tooltipContent }) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleShowTooltip = () => setShowTooltip(true)
  const handleHideTooltip = () => {
    setTimeout(() => {
      setShowTooltip(false)
    }, 1000)
  }

  return (
    <S.StyledTooltipWrapper
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      onTouchStart={handleShowTooltip}
      onTouchEnd={handleHideTooltip}
    >
      {children}
      {showTooltip && <S.StyledTooltip>{tooltipContent}</S.StyledTooltip>}
    </S.StyledTooltipWrapper>
  )
}

export default Tooltip
