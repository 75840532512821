import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const Container = styled.div`
  width: 74px;
  height: 38px;
  background: ${COLORS.black_28};
  border: 1px solid ${COLORS.white_f8};
  color: ${COLORS.white_f8};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
`
