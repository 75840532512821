import React from 'react'

export function CloseIcon({ stroke = '#F8F8F8', ...rest }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...rest}
    >
      <path
        d='M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
