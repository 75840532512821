import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATUSES } from 'utils/constants'
import { friendsCollect, getFriends } from './actions'

const initialState = {
  friends: [],
  friendsCount: 0,
  loadFriendsListStatus: LOADING_STATUSES.idle,
  collectFriendsListStatus: LOADING_STATUSES.idle,
}

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getFriends.pending, (state) => {
      state.loadFriendsListStatus = LOADING_STATUSES.pending
    })
    addCase(getFriends.fulfilled, (state, { payload }) => {
      state.friends = payload
      state.friendsCount = payload?.length
      state.loadFriendsListStatus = LOADING_STATUSES.succeeded
    })
    addCase(getFriends.rejected, (state) => {
      state.loadFriendsListStatus = LOADING_STATUSES.failed
    })
    addCase(friendsCollect.pending, (state) => {
      state.collectFriendsListStatus = LOADING_STATUSES.pending
    })
    addCase(friendsCollect.fulfilled, (state) => {
      state.collectFriendsListStatus = LOADING_STATUSES.succeeded
    })
    addCase(friendsCollect.rejected, (state) => {
      state.collectFriendsListStatus = LOADING_STATUSES.failed
    })
  },
})

export const friendsReducer = friendsSlice.reducer

export const friendsActions = {
  ...friendsSlice.actions,
  getFriends,
  friendsCollect,
}
