import { Image } from 'assets/images'
import styled from 'styled-components'

export const FarmBubbleAnimationContainer = styled.div`
  min-height: calc(100vh - 560px);
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const FarmBigImage = styled.img`
  position: absolute;
  z-index: 100;
`

export const FarmImageWrapper = styled.div`
  width: 164px;
  height: 164px;
  position: absolute;
  z-index: 100;

  @media (max-height: 650px) {
    width: 130px;
    height: 130px;
  }

  @media (max-height: 570px) {
    width: 100px;
    height: 100px;
  }

  @media (max-height: 530px) {
    width: 70px;
    height: 70px;
  }
`

export const SmallBubble = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url(${Image.farmBig});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;

  @media (max-height: 570px) {
    width: 40px;
    height: 40px;
  }

  &.left {
    animation: move-bubbles-left var(--random-duration) linear infinite;
    left: -50px;
    top: calc(10% + (80% * var(--random-top)));
    z-index: 1;
  }

  &.right {
    animation: move-bubbles-right var(--random-duration) linear infinite;
    right: -50px;
    top: calc(10% + (80% * var(--random-top)));
    z-index: 1;
  }

  @keyframes move-bubbles-left {
    0% {
      left: -50px;
      transform: scale(1);
      opacity: 1;
    }
    85% {
      transform: scale(0.1);
      opacity: 1;
    }
    100% {
      left: 40%;
      transform: scale(0.1);
      opacity: 0;
      top: 50%;
    }
  }

  @keyframes move-bubbles-right {
    0% {
      right: -50px;
      transform: scale(1);
      opacity: 1;
    }
    85% {
      transform: scale(0.1);
      opacity: 1;
    }
    100% {
      right: 40%;
      transform: scale(0.1);
      opacity: 0;
      top: 50%;
    }
  }
`
