import React, { useState } from 'react'

import { ScreenDialog } from 'common/ScreenDialog'
import NotFoundDialogContent from 'components/NotFound/DialogContent/NotFoundDialogContent'

function NotFound() {
  const [isDialogOpen, setIsDialogOpen] = useState(true)

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  return (
    <ScreenDialog isOpen={isDialogOpen} closeDialog={closeDialog}>
      <NotFoundDialogContent />
    </ScreenDialog>
  )
}

export default NotFound
