import React from 'react'

export function TwitterIcon() {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.6009 4.5H19.0544L13.6943 10.8538L20 19.5H15.0627L11.1957 14.2562L6.77087 19.5H4.31595L10.049 12.7038L4 4.5H9.06262L12.5581 9.29308L16.6009 4.5ZM15.7399 17.9769H17.0993L8.32392 5.94308H6.86506L15.7399 17.9769Z'
        fill='#F8F8F8'
      />
    </svg>
  )
}
