import styled from 'styled-components'
import { COLORS } from 'utils/constants'

export const Container = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: -16px;
`

export const CoinText = styled.p`
  color: ${COLORS.white_f8};
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
`
