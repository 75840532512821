import React from 'react'
import { Image } from 'assets/images'
import * as S from './styled'

export function FriendsNotExistScreen() {
  const steps = [
    {
      title: 'You share your link',
    },
    {
      title: 'Your friends joins the Era',
    },
    {
      title: 'You receive 100 point',
    },
  ]

  return (
    <S.FriendsNotExistScreenWrapper>
      <S.InviteFriendsBanner>
        <S.BannerLeftView>
          <S.BannerTitle>Invite your friends</S.BannerTitle>
          <S.BannerText>Earn points</S.BannerText>
        </S.BannerLeftView>
        <S.BannerRightView>
          <S.Emoji src={Image.personEmoji} alt='emojis' />
        </S.BannerRightView>
      </S.InviteFriendsBanner>
      <S.InviteStepsWrapper>
        {steps.map((step) => (
          <S.Steps key={step.title}>
            <S.StepTopPart>
              <S.StepCircle />
              <S.StepTitle>{step.title}</S.StepTitle>
            </S.StepTopPart>
            <S.Line className='line' />
          </S.Steps>
        ))}
      </S.InviteStepsWrapper>
    </S.FriendsNotExistScreenWrapper>
  )
}
