import styled, { keyframes } from 'styled-components'
import { COLORS } from 'utils/constants'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const WheelsWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const EnsureWheelsHeight = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
`

export const EachWheelWrapper = styled.div`
  position: absolute;
  width: 145%;
  height: 145%;
  top: 0;
  right: -66%;
  padding: ${({ padding }) => padding}px;

  z-index: 1;

  @media (max-width: 380px) {
    padding: ${({ padding }) => padding / 1.2}px;
  }

  @media (max-width: 330px) {
    padding: ${({ padding }) => padding / 1.3}px;
  }
`

export const WinnerDivWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  background-color: transparent;
  top: 0;
  left: 0;
  position: absolute;
`

export const WinnerDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 194px;
  height: 64px;
  border-radius: 100px;
  border: 2px solid ${COLORS.blue_op};
  z-index: 5;
  background-color: transparent;
  animation: ${fadeIn} 0.2s forwards;

  @media (max-width: 380px) {
    width: 160px;
    height: 50px;
  }

  @media (max-width: 330px) {
    width: 142px;
    height: 34px;
  }
`

export const WheelOfFortune = styled.div`
  --_items: 8;
  all: unset;
  aspect-ratio: 1 / 1;
  container-type: inline-size;
  direction: ltr;
  display: grid;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 1;
`

export const List = styled.ul`
  all: unset;
  position: relative;
  clip-path: inset(0 0 0 0 round 50%);
  display: grid;
  inset: 0;
  place-content: center start;
`

export const EnsureSmoothBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
`

export const WheelImage = styled.img`
  margin-left: 6px;
  width: 40px;
  position: relative;
  z-index: 10;

  @media (max-width: 380px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 330px) {
    width: 20px;
    height: 20px;
  }
`

export const ListItem = styled.li`
  position: relative;
  z-index: 10;
  align-content: center;
  aspect-ratio: 1 / calc(2 * tan(180deg / var(--_items)));
  background: ${({ bgColor }) => bgColor};
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  display: grid;
  font-size: 5cqi;
  grid-area: 1 / -1;
  padding-left: 10px;
  rotate: calc(360deg / var(--_items) * calc(var(--_idx) - 1));
  transform-origin: center right;
  user-select: none;
  width: 50cqi;

  &:nth-of-type(1) {
    --_idx: 1;
  }
  &:nth-of-type(2) {
    --_idx: 2;
  }
  &:nth-of-type(3) {
    --_idx: 3;
  }
  &:nth-of-type(4) {
    --_idx: 4;
  }
  &:nth-of-type(5) {
    --_idx: 5;
  }
  &:nth-of-type(6) {
    --_idx: 6;
  }
  &:nth-of-type(7) {
    --_idx: 7;
  }
  &:nth-of-type(8) {
    --_idx: 8;
  }
`
