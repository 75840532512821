import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from './router'

export function Router() {
  return (
    <Routes>
      {routes.map(({ path, component: Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
    </Routes>
  )
}
