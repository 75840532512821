import React from 'react'
import CardGrid from 'components/CardGrid'
import { TotalERVCoin } from 'components/TotalERVCoin'
import { useSelector } from 'react-redux'
import { userSelector } from 'store/user/selectors'
import { formatCoin } from 'utils/format-coin'

function Home() {
  const { userTotalCoins } = useSelector(userSelector)

  return (
    <>
      <TotalERVCoin totalCoin={formatCoin(userTotalCoins)} />
      <CardGrid />
    </>
  )
}

export default Home
