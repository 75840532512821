import styled, { css } from 'styled-components'
import { COLORS } from '../../utils/constants'

const StyledButton = styled.button`
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: ${({ padding = '12px' }) => padding};
  font-size: ${({ fontSize = '14px' }) => fontSize};
  color: ${({ textColor, isLoading, disabled }) =>
    isLoading ? 'transparent' : disabled ? COLORS.gray : textColor};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? 'fit-content'};
  max-height: ${({ maxHeight }) => maxHeight ?? ''};
  background: ${({ bgColor, disabled }) =>
    disabled ? COLORS.dark_43 : bgColor};
  border: 1.5px solid
    ${({ borderColor, disabled }) => (disabled ? 'none' : borderColor)};
  border-radius: 6px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      --_g: no-repeat
        radial-gradient(circle closest-side, ${COLORS.white} 90%, transparent);
      background: var(--_g) calc(100% / 3) 50%, var(--_g) 50% 50%,
        var(--_g) calc(100% / 1.5) 50%;
      background-size: calc(100% / 3) 50%;
      animation: l7 1s infinite linear;
      @keyframes l7 {
        33% {
          background-size: calc(100% / 3) 0%, calc(100% / 3) 50%,
            calc(100% / 3) 50%;
        }
        50% {
          background-size: calc(100% / 3) 50%, calc(100% / 3) 0%,
            calc(100% / 3) 50%;
        }
        66% {
          background-size: calc(100% / 3) 50%, calc(100% / 3) 50%,
            calc(100% / 3) 0%;
        }
      }
    `}
`

export default StyledButton
