import React, { useMemo } from 'react'
import { Image } from 'assets/images'
import Button from 'common/Button'
import { formatCoin } from 'utils/format-coin'
import { ButtonText } from 'components/NavBar/styled'
import * as S from './styled'

export function FriendsList({
  friends,
  friendsCount,
  onClaimClick,
  farmedCoins,
}) {
  const isClaimButtonDisable = useMemo(() => {
    return friends.reduce((elem, acc) => {
      return elem + acc.coins
    }, 0)
  }, [friends])

  return (
    <S.FriendsListWrapper>
      <S.FriendsListHeader>
        <S.FriendsListHeaderLeftPart>
          <S.HeaderTitle>
            Get <span>10%</span> from your friends
          </S.HeaderTitle>
          <S.HeaderSubtitle>
            Totally claimed: &nbsp;
            <S.FarmIcon src={Image.coin} />
            &nbsp; {formatCoin(farmedCoins)}
          </S.HeaderSubtitle>
        </S.FriendsListHeaderLeftPart>
        <Button
          text='Claim'
          onClick={onClaimClick}
          isDisabled={!isClaimButtonDisable}
        />
      </S.FriendsListHeader>
      <S.FriendsCount>
        Friends <span>({friendsCount})</span>
      </S.FriendsCount>
      <S.FriendsList>
        {friends?.map((friend) => (
          <S.Friend key={friend.referral._id}>
            <S.FriendInfo>
              <S.FriendAvatar src={Image.avatar} alt={friend.name} />
              <S.FriendName>
                <ButtonText maxWidth='120'>
                  {friend.referral.username}
                </ButtonText>
              </S.FriendName>
            </S.FriendInfo>
            {friend.profitable && (
              <S.FriendPoints>
                <S.FriendPointsText>
                  {formatCoin(friend.referral.coins)}
                </S.FriendPointsText>
                <S.FriendPointsIcon src={Image.coin} />
              </S.FriendPoints>
            )}
          </S.Friend>
        ))}
      </S.FriendsList>
    </S.FriendsListWrapper>
  )
}
