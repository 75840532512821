import React from 'react'

export function TotalCoin() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M13.2 16.6C16.9556 16.6 20 13.5556 20 9.8C20 6.04446 16.9556 3 13.2 3C9.44449 3 6.40002 6.04446 6.40002 9.8C6.40002 13.5556 9.44449 16.6 13.2 16.6Z'
        stroke='#F8F8F8'
        strokeWidth='1.275'
        strokeLinecap='round'
      />
      <path
        d='M12.4919 19.1236C11.5536 19.6803 10.4581 20 9.28788 20C5.81517 20 3 17.1848 3 13.7121C3 12.5419 3.31966 11.4465 3.87641 10.5081'
        stroke='#F8F8F8'
        strokeWidth='1.275'
        strokeLinecap='round'
      />
    </svg>
  )
}
