export const getNextTimeAt16 = () => {
  const now = new Date()
  const nextUTC00 = new Date()
  nextUTC00.setUTCHours(0, 0, 0, 0)

  if (now.getTime() > nextUTC00.getTime()) {
    nextUTC00.setUTCDate(nextUTC00.getUTCDate() + 1)
  }

  return nextUTC00
}
